import { colorSystem } from "goi_common";

const title = "전라 광주 지역 장례 추천 포털";

export const parlorRegion = {
  defaultMetaData: {
    pageOgTitle: title,
    pageDescription: `${title} 장례식장 상세 비용, 가장 저렴한 ${title} 장례식장 비용 정보, 주변 장례식장과 비교하기.`,
    pageKeywords:
      "장례, 장례식, 가족장, 무빈소장례, 장례지도사, 장례식장, 장례 비용, 상조, 상조 회사, 상조회, 상조가입, 상조추천, 후불제상조, 장례상조, 상조서비스, 장례식 절차, 장례 절차, 임종, 고인, 빈소, 임종",
    pageOgDescription: `${title} 장례식장 상세 비용, 가장 저렴한 ${title} 장례식장 비용 정보, 주변 장례식장과 비교하기.`,
    pageOgImage: "",
  },

  layout: {
    headTitle: title,
    layoutStyle: {
      background: "white",
      borderBottom: "1px solid #bdbdbd",
      color: "#7d7d7d",
      height: "58px",
    },
    ctaButtonStyle: {
      border: "none",
      color: "white",
      background: colorSystem.darkgray.c400,
    },
  },
  targetSidos: ["24", "35", "36"],

  regionColor: colorSystem.darkgray,

  bestParlorIds: [268, 313, 1162, 178, 667],

  baseUrl: "https://www.honam-funeralcenter.co.kr",
};
