import { AppProps } from "next/app";
import Layout from "../components/Layout";
import { ThemeProvider } from "@mui/material";
import { OverlayProvider } from "@toss/use-overlay";
import {
  GlobalStyle,
  HackleClient,
  getUserProperties,
  initUserProperties,
  muiTheme,
  useTrackingGoiAnchorTag,
} from "goi_common";
import Head from "next/head";
import { HackleProvider, getUserId } from "@hackler/react-sdk";
import { useEffect } from "react";
import * as gtag from "../constants/gtags";
import Script from "next/script";
import { parlorRegion } from "@/constants/parlorRegion";

const { defaultMetaData } = parlorRegion;

const App = ({ Component, pageProps }: AppProps) => {
  /** GA 크로스도메인 연동 */
  useTrackingGoiAnchorTag();

  useEffect(() => {
    if (HackleClient) {
      initUserProperties();
      let id = getUserId();

      const user = {
        userId: id,
        properties: getUserProperties(),
      };

      HackleClient.setUser(user);
    }
  }, []);

  const googleTagManagerScript = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtag.GTM_ID}');
    `;

  return (
    <>
      <Head>
        <title>
          {pageProps.pageOgTitle
            ? pageProps.pageOgTitle
            : defaultMetaData.pageOgTitle}
        </title>
      </Head>
      {process.env.NODE_ENV === "production" && (
        <Script
          id="gtm"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{ __html: googleTagManagerScript }}
        />
      )}

      <ThemeProvider theme={muiTheme}>
        <GlobalStyle />
        <HackleProvider hackleClient={HackleClient} supportSSR>
          <OverlayProvider>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </OverlayProvider>
        </HackleProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
